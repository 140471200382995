import React from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Button } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";

const GenerateExcel = ({ excelData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
  const fileExtension = ".xlsx";
  var wscols = [
    { wch: 15 },
    { wch: 50 },
    { wch: 17 },
    { wch: 40 },
    { wch: 17 },
    { wch: 20 },
    { wch: 100 },
  ];
//   const addStyles = (workSheet) => {

//     Object.entries(workSheet)
//     .filter(([k, v]) => k.charAt(0) === "A")
//     .forEach(([k, v]) => {
//       //console.log(k);
//       //console.log("v");
//       //console.log(v.v);

//       if (v.v === "Year") {
//         v.s = {
//           fill: {
//             fgColor: { rgb: "D0D8e2" },
//           },
//           alignment: {
//             vertical: "center",
//             horizontal: "center",
//           },
//           font: {
//             bold: true,
//           }
//         };
//       } else {
//         v.s = {
//           alignment: {
//             vertical: "center",
//             horizontal: "center",
//           },
//           width: {
//             auto: true,
//           },
//         };
//       }
//     });

//     Object.entries(workSheet)
//     .filter(([k, v]) => k.charAt(0) === "B")
//     .forEach(([k, v]) => {
//       //console.log(k);
//       //console.log("v");
//       //console.log(v.v);
//       if (v.v === "VendorName") {
//         v.v = "Vendor";
//         v.s = {
//           fill: {
//             fgColor: { rgb: "D0D8e2" },
//           },
//           alignment: {
//             vertical: "center",
//             horizontal: "center",
//           },
//           font: {
//             bold: true,
//           }
         
//         };
//       }
//       else{
//         v.v = v.v.toUpperCase();
//         v.s = {
//           alignment: {
//             vertical: "center",
//             horizontal: "center",
//           },
//           width: {
//             auto: true,
//           },
//         };
//       }
      
//     });


//     Object.entries(workSheet)
//       .filter(([k, v]) => k.charAt(0) === "C")
//       .forEach(([k, v]) => {
//         //console.log(k);
//         //console.log("v");
//         //console.log(v.v);

//         if (v.v === "High") {
//           v.s = {
//             font: {
//               sz: 12,
//               color: { rgb: "FFFFFF" },
//             },
//             fill: {
//               fgColor: { rgb: "E3A9A9" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
           
//           };
//         } else if (v.v === "Low") {
//           v.s = {
//             font: {
//               sz: 12,
//               color: { rgb: "FFFFFF" },
//             },
//             fill: {
//               fgColor: { rgb: "90EE90" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
           
//           };
//         } else if (v.v === "Moderate") {
//           v.s = {
//             font: {
//               sz: 12,
//               color: { rgb: "FFFFFF" },
//             },
//             fill: {
//               fgColor: { rgb: "FFFFAA00" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
           
//           };
//         } else if (v.v === "Critical") {
//           v.s = {
//             font: {
//               sz: 12,
//               color: { rgb: "FFFFFF" },
//             },
//             fill: {
//               fgColor: { rgb: "FF0000" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
         
//           };
//         } else if (v.v === "Very Low") {
//           v.s = {
//             font: {
//               sz: 12,
//               color: { rgb: "FFFFFF" },
//             },
//             fill: {
//               fgColor: { rgb: "C0E5C0" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
           
//           };
//         } else if (v.v === "Inherent_Risk") {
//           v.v = "Inherent Risk";
//           v.s = {
//             fill: {
//               fgColor: { rgb: "D0D8e2" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
//             font: {
//               bold: true,
//             }
         
//           };
//         } else {
//           v.s = {
//             font: {
//               sz: 12,
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
           
//           };
//         }
//       });

//       Object.entries(workSheet)
//       .filter(([k, v]) => k.charAt(0) === "D")
//       .forEach(([k, v]) => {
//         //console.log(k);
//         //console.log("v");
//         //console.log(v.v);
//         if (
//           v.v === "Work in Progress" ||
//           v.v === "In-Progress" ||
//           v.v === "Annual Reassessment In Progress"
//         ) {
//           v.s = {
//             font: {
//               sz: 12,
//               color: { rgb: "FFFFFF" },
//             },
//             fill: {
//               fgColor: { rgb: "4F66D3" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
           
//           };
//         } else if (
//           v.v === "Closed Complete" ||
//           v.v === "Annual Reassessment Completed" ||
//           v.v === "Completed"
//         ) {
//           v.s = {
//             font: {
//               sz: 12,
//               color: { rgb: "FFFFFF" },
//             },
//             fill: {
//               fgColor: { rgb: "008000" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
          
//           };
//         } else if (
//           v.v === "Closed Incomplete" ||
//           v.v === "Cancelled" ||
//           v.v === "Incompleted"
//         ) {
//           v.s = {
//             font: {
//               sz: 12,
//               color: { rgb: "FFFFFF" },
//             },
//             fill: {
//               fgColor: { rgb: "FF0000" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
//           };
//         } else if (v.v === "NA" || v.v === "NA_in_Snow") {
//           v.s = {
//             font: {
//               sz: 12,
//               color: { rgb: "FFFFFF" },
//             },
//             fill: {
//               fgColor: { rgb: "E3A9A9" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
           
//           };
//         } 
//         else if (v.v === "state") {
//           v.v = "State";
//           v.s = {
//             fill: {
//               fgColor: { rgb: "D0D8e2" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
//             font: {
//               bold: true,
//             }
          
//           };
//         }
//         else {
//           v.s = {
//             font: {
//               sz: 12,
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
           
//           };
//         }
//       });

//     Object.entries(workSheet)
//       .filter(([k, v]) => k.charAt(0) === "E")
//       .forEach(([k, v]) => {
//         //console.log(k);
//         //console.log("v");
//         //console.log(v.v);

//         if (v.v === "High") {
//           v.s = {
//             font: {
//               sz: 12,
//               color: { rgb: "FFFFFF" },
//             },
//             fill: {
//               fgColor: { rgb: "E3A9A9" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
           
//           };
//         } else if (v.v === "Low") {
//           v.s = {
//             font: {
//               sz: 12,
//               color: { rgb: "FFFFFF" },
//             },
//             fill: {
//               fgColor: { rgb: "90EE90" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
           
//           };
//         } else if (v.v === "Moderate") {
//           v.s = {
//             font: {
//               sz: 12,
//               color: { rgb: "FFFFFF" },
//             },
//             fill: {
//               fgColor: { rgb: "FFFFAA00" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
          
//           };
//         } else if (v.v === "Critical" || v.v === "NA") {
//           v.s = {
//             font: {
//               sz: 12,
//               color: { rgb: "FFFFFF" },
//             },
//             fill: {
//               fgColor: { rgb: "FF0000" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
         
//           };
//         } else if (v.v === "Very Low") {
//           v.s = {
//             font: {
//               sz: 12,
//               color: { rgb: "FFFFFF" },
//             },
//             fill: {
//               fgColor: { rgb: "C0E5C0" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
          
//           };
//         } else if (v.v === "Residual_Risk") {
//           v.v = "Residual Risk";
//           v.s = {
//             fill: {
//               fgColor: { rgb: "D0D8e2" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
//             font: {
//               bold: true,
//             }
//           };
//         } else {
//           v.s = {
//             font: {
//               sz: 12,
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
           
//           };
//         }
//       });

//       Object.entries(workSheet)
//       .filter(([k, v]) => k.charAt(0) === "F")
//       .forEach(([k, v]) => {
//         //console.log(k);
//         //console.log("v");
//         //console.log(v.v);
//         if (v.v === "Category") {
//           v.s = {
//             fill: {
//               fgColor: { rgb: "D0D8e2" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
//             font: {
//               bold: true,
//             }
           
//           };
//         } else {
//           v.s = {
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
//           };
//         }
//       });

  

//     Object.entries(workSheet)
//       .filter(([k, v]) => k.charAt(0) === "G")
//       .forEach(([k, v]) => {
//         //console.log(k);
//         //console.log("v");
//         //console.log(v.v);

//         if (v.v === "number") {
//           v.v = "Snow Ticket URL";
//           v.s = {
//             fill: {
//               fgColor: { rgb: "D0D8e2" },
//             },
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
//             font: {
//               bold: true,
//             }
          
//           };
//         } else {
//            v.v = `https://marsprod.service-now.com/nav_to.do?uri=sc_req_item.do?sysparm_query=number=${v.v}`
//           // XLSX.addLink(v,  `https://marsprod.service-now.com/nav_to.do?uri=sc_req_item.do?sysparm_query=number=${v.v}`);

//           v.s = {
//             alignment: {
//               vertical: "center",
//               horizontal: "center",
//             },
//           };
//         }
//       });

//   };

  const excelDataLength = excelData.length;
  const rowHeight = Array(excelDataLength)
    .fill()
    .map((item) => ({ hpt: 30 }));

  // console.log("rowHeight")
  //console.log(rowHeight,excelDataLength)

  const exportTOExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const range = ws["!ref"];

    ws["!autofilter"] = { ref: range };
    // addStyles(ws);
    ws["!cols"] = wscols;
    ws["!rows"] = rowHeight;

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const exelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([exelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div>
      

      <button title="Export in Excel" onClick={(e) => exportTOExcel(fileName)} style={{width:"50px",height:"45px",paddingLeft:"14px",paddingRight:"14px",borderRadius:"6px",borderWidth:"1px"}}><FaDownload/></button>

    </div>
  );
};

export default GenerateExcel;
