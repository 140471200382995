import React, { useEffect, useState } from 'react'
import MainLayout from "../nav/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import pathImg from '../assests/img/homeimg.svg'
import AutoSlider from '../layout/AutoSlider';
import PieChartsApex from '../chats/PieChartsApex'
import VericalBarCharts from '../chats/VericalBarCharts'
import CustomCards from '../layout/CustomCards';
import DonorListData from '../../donorList.json'
import electricalBondList from '../../EB.json'
import { Link } from 'react-router-dom';



const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const Home = () => {
  const [totalDenominations, setTotalDenominations] = useState({});
  const [totalDenominationsCrore, settotalDenominationsCrore] = useState(0);
  const [totalDenominations1, setTotalDenominations1] = useState([]);
  const [totalDenominationsCrore1, settotalDenominationsCrore1] = useState(0);
  const [topfivedonor, setTopFiveDonor] = useState([]);
  const [topfivedonor1, setTopFiveDonor1] = useState([]);
  const [nameOFLastRedeemerEB, setnameOFLastRedeemerEB] = useState();
  const [nameOFHighestRedeemerEB, setnameOFHighestRedeemerEB] = useState();
  const [nameOFHighestRedeemerDonor, setnamenameOFHighestRedeemerDonor] = useState();
  const [nameOFlowsetRedeemerDonor, setnameOFlowsetRedeemerDonor] = useState();

  useEffect(() => {

    let jsonData = electricalBondList.filter(obj => !obj["Sr No."].startsWith("Page"));

    let jsonData1 = DonorListData.filter(obj => !obj["Sr No."].startsWith("Page"));




    const calculateTotalDenominations = () => {
      const totalDenominations = {};
      const additionCounts = {}; // Object to track how many times denomination is added
    
      jsonData.forEach((entry) => {
        const party = entry["Name of the Political Party"];
        const denomination = parseInt(entry["Denomination"].replace(/,/g, ""));
        totalDenominations[party] = (totalDenominations[party] || 0) + denomination;
        additionCounts[party] = (additionCounts[party] || 0) + 1;
      });
    
      const sortedDenominations = Object.entries(totalDenominations)
        .sort((a, b) => b[1] - a[1])
        .map(([party, denomination]) => ({ name: party, denomination, additionCounts: additionCounts[party] }));
    
      setTotalDenominations(sortedDenominations);
    
      let totalSum = 0;
      sortedDenominations.forEach(({ denomination }) => {
        totalSum += denomination;
      });
    
      const totalSumInCrores = totalSum / 10000000;
      settotalDenominationsCrore(totalSumInCrores);
      // console.log("Total Sum of Denominations:", totalSumInCrores);
    };
    
  
  
    const calculateTotalDenominations1 = () => {
      const totalDenominations = {};
      const additionCounts = {}; // Object to track how many times denomination is added
    
      jsonData1.forEach((entry) => {
        const party = entry["Purchaser Name"]; // Adjusted to "Purchaser Name"
        const denomination = parseInt(entry["Denomination"].replace(/,/g, ""));
        totalDenominations[party] = (totalDenominations[party] || 0) + denomination;
        additionCounts[party] = (additionCounts[party] || 0) + 1;
      });
    
      const sortedDenominations = Object.entries(totalDenominations)
        .sort((a, b) => b[1] - a[1])
        .map(([party, denomination]) => ({ name: party, denomination, additionCounts: additionCounts[party] }));
    
      setTotalDenominations1(sortedDenominations);
    
      let totalSum = 0;
      sortedDenominations.forEach(({ denomination }) => {
        totalSum += denomination;
      });
    
      const totalSumInCrores = totalSum / 10000000;
      settotalDenominationsCrore1(totalSumInCrores);
    };
    

    calculateTotalDenominations();
    calculateTotalDenominations1();

  }, []);

  function convertToCrore(number) {
    // Check if the input is a valid number
    if (typeof number !== 'number' || isNaN(number)) {
        return "Invalid input. Please provide a valid number.";
    }

    // Convert the number to crore
    const crore = number / 10000000;

    // Return the result
    return crore;
}

  const DonorListDatapercentage = (DonorListData.length - electricalBondList.length)/ DonorListData.length * 100;
  const eleListDatapercentage = (electricalBondList.length - DonorListData.length)/ electricalBondList.length * 100;

  // Round the percentage to two decimal places
  const roundedPercentage = DonorListDatapercentage.toFixed(2);
  const eleroundedPercentage = eleListDatapercentage.toFixed(2);


  useEffect(() => {
    // Check if totalDenominations is an array
    if (Array.isArray(totalDenominations)) {

        const top5EBList = totalDenominations.slice(0, 5);
        const nameOFLowRedeemer = top5EBList.slice(0,1)
        const lastrevseseData  = totalDenominations
        const revData = lastrevseseData.reverse()
        const lastfive = revData.slice(0,1)


          setnameOFLastRedeemerEB(lastfive.map(item => ({ name: item.name, amount:item.denomination  })));
          setnameOFHighestRedeemerEB(nameOFLowRedeemer.map(item => ({ name: item.name, amount:item.denomination  })));


        setTopFiveDonor(top5EBList);
    } else {
        console.log("totalDenominations is not an array.");
    }
}, [totalDenominations]);

useEffect(() => {
  // Check if totalDenominations is an array
  if (Array.isArray(totalDenominations1)) {

      const top5DonorList = totalDenominations1.slice(0, 5);
      const nameOFHighRedeemer = top5DonorList.slice(0,1)
      const lastrevseseData  = totalDenominations1
      const revData = lastrevseseData.reverse()
      const lastfive = revData.slice(0,1)

      setnameOFlowsetRedeemerDonor(lastfive.map(item => ({ name: item.name, amount:item.denomination  })));
      setnamenameOFHighestRedeemerDonor(nameOFHighRedeemer.map(item => ({ name: item.name, amount:item.denomination  })));

      setTopFiveDonor1(top5DonorList);
  } else {
      console.log("totalDenominations is not an array.");
  }
}, [totalDenominations1]);






  return (
    <div>
      <Box
        className="mainLayout"
        sx={{ display: "flex", overflow: "hidden", overflowY: "auto" }}
      >
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div id="mainHome">
            <div style={{ padding: "5px", display: "grid", gap: "2rem" }}>
              <div
                style={{
                  display: "grid",
                  gap: "1rem",
                  gridTemplateColumns: "70% 29%",
                }}
              >
                <div className="homePage_card">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "3rem",
                      alignContent:"center",
                      alignItems:"center"
                    }}
                  >
                    <div className="box-flex-1">
                      <div className="welcome-back" style={{ display: "grid" }}>
                        <span className="welcome-back-text-1">
                          Analysis of Donor​👋
                        </span>
                        <span className="welcome-back-text-2">
                             What is Electoral Bonds​
                        </span>
                      </div>

                      <span>
                      Electoral Bond financial instrument introduced in India in 2018 as a means of making donations to political parties. They were introduced by the Government of India as a mechanism to bring transparency and accountability to political funding
                      </span>
                      <div style={{marginTop:"10px"}} className="box-cls-2">
                        <Link to={`/electoral`}>
                            <button className="btn_go_now">Go Now</button>
                        </Link>
                      </div>
                    </div>

                    <div className="box-flex-2">
                      <img
                        style={{ width: "100%" }}
                        alt="imgData"
                        src={pathImg}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#eee",
                    height: "300px",
                    borderRadius: "6px",
                  }}
                >
                  <AutoSlider />
                </div>
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "19% 19% 21% 19% 19%",
                  gap: "10px",
                }}
              >
                

                

                <div>
                  <CustomCards
                    title="Encashed Amount"
                    className="cardcls"
                    number={totalDenominationsCrore1.toFixed(2) + "Cr"}
                    isDown={false}
                    count={20 + "%"}
                    status="increase"
                  />
                </div>

                <div>
                  <CustomCards
                    title="No Of EB Encashed"
                    className="cardcls"
                    number={electricalBondList.length}
                    isDown={true}
                    count={eleroundedPercentage + "%"}
                    status="decrease"
                  />
                </div>

                <div id='pariesId'>
                  <CustomCards
                    title="No of Political Party"
                    className="cardcls"
                    number={totalDenominations1.length}
                    isDown={true}
                    count={""}
                    status="decrease"
                  />
                </div>

                <div>
                  <CustomCards
                    title="Highest Redeemer"
                    className="HighestRedeemer"
                    number={nameOFHighestRedeemerEB && nameOFHighestRedeemerEB.map((item) => {
                      return item.name
                    })}
                    isDown={false}
                    count={nameOFHighestRedeemerEB && nameOFHighestRedeemerEB.map((item) => {
                      return convertToCrore(item.amount)
                    })}
                  />
                </div>

                <div>
                  <CustomCards
                    title="Lowest  Redeemer"
                    className="HighestRedeemer1"
                    number={nameOFLastRedeemerEB && nameOFLastRedeemerEB.map((item) => {
                      return item.name
                    })}
                    isDown={false}
                    count={nameOFLastRedeemerEB && nameOFLastRedeemerEB.map((item) => {
                      return convertToCrore(item.amount)
                    })}
                  />
                </div>

             

               

                

              </div>

              <div
                style={{
                  display: "grid",
                  gap: "1rem",
                  gridTemplateColumns: "29% 70%",
                }}
              >
                <div
                  style={{
                    padding: "0px",
                    boxShadow: "0px 0px 4px rgba(0,0,0,0.2)",
                    backgroundColor: "white",
                    display:"grid",
                    alignContent:"center",
                    alignItems:"center"
                  }}
                  className="homePage_card_1"
                >
                  <PieChartsApex data={topfivedonor}/>
                </div>

                <div
                  style={{
                    padding: "0px",
                    boxShadow: "0px 0px 4px rgba(0,0,0,0.2)",
                    backgroundColor: "white",
                  }}
                  className="homePage_card_1"
                >
                    <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 4px",
                    borderRadius: "6px",
                    padding: "1rem",
                  }}
                  className="pieChatsDiv"
                >
                  <span
                    style={{
                      padding: "10px",
                      display: "flex",
                      fontWeight: "600",
                      justifyContent: "space-between",
                      alignContent:"center",
                      alignItems:"center"

                    }}
                  >
                    <span
                      style={{
                        display: "block",
                        fontWeight: "600",
                        fontSize:"20px"
                      }}
                    >
                      Top 5 EB
                    </span>

                    <span
                      style={{
                        background: "#eee",
                        padding: "5px",
                        width: "100px",
                        textAlign: "center",
                        borderRadius: "6px",
                        display:"grid",
                        alignContent:"center",
                        alignItems:"center"
                      }}
                    >
                      View
                    </span>
                  </span>
                  <table style={{ width: "100%",padding:"0px" }} id="tableId">
                    <tbody>
                      <>
                        <tr>
                          <th>Name</th>
                          <th>Denomination(in Cr)</th>
                          <th>No of Purchase</th>

                        </tr>
                      </>

                      {Array.isArray(topfivedonor) &&
                        topfivedonor.slice(0, 5).map((item) => {
                          return (
                            <tr>
                              <td>{item.name}</td>
                              <td>{convertToCrore(item.denomination)}</td>
                              <td>{item.additionCounts}</td>

                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                </div>
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "19% 19% 21% 19% 19%",
                  gap: "10px",
                }}
              >
               

               

                <div>
                  <CustomCards
                    title="Donation Amount​"
                    className="donorCard2"
                    number={totalDenominationsCrore + "Cr"}
                    isDown={true}
                    count={15}
                    status="decrease"
                  />
                </div>

                <div>
                  <CustomCards
                    title="Number of donor​"
                    className="donorCard2"
                    number={DonorListData.length}
                    isDown={parseInt(roundedPercentage) < 0 ? false : true}
                    count={roundedPercentage}
                    status="increase"
                  />
                </div>

                <div>
                  <CustomCards
                    title="EB purchased"
                    className="donorCard2"
                    number={totalDenominationsCrore1.toFixed(2) + "Cr"}
                    isDown={false}
                    count={20}
                    status="increase"
                  />
                </div>

                <div>
                  <CustomCards
                    title="Highest donor"
                    className="HighestRedeemer donorCard2"
                    number={nameOFHighestRedeemerDonor && nameOFHighestRedeemerDonor.map((item) => {
                      return item.name
                    })}
                    isDown={false}
                    count={nameOFHighestRedeemerDonor && nameOFHighestRedeemerDonor.map((item) => {
                      return convertToCrore(item.amount).toFixed(2)
                    })}
                  />
                </div>

                <div>
                  <CustomCards
                    title="Lowest donor"
                    className="HighestRedeemer1 donorCard2"
                    number={nameOFlowsetRedeemerDonor && nameOFlowsetRedeemerDonor.map((item) => {
                      return item.name
                    })}
                    isDown={false}
                    count={nameOFlowsetRedeemerDonor && nameOFlowsetRedeemerDonor.map((item) => {
                      return convertToCrore(item.amount).toFixed(2)
                    })}
                  />
                </div>

                

              </div>

              <div
                style={{
                  display: "grid",
                  gap: "1rem",
                  gridTemplateColumns: "65% 35%",
                }}
              >
                <div
                  style={{
                    padding: "0px",
                    boxShadow: "0px 0px 4px rgba(0,0,0,0.2)",
                    backgroundColor: "white",
                  }}
                  className="homePage_card_1"
                >
                    <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 4px",
                    borderRadius: "6px",
                    padding: "1rem",
                  }}
                  className="pieChatsDiv"
                >
                  <span
                    style={{
                      padding: "10px",
                      display: "flex",
                      fontWeight: "600",
                      justifyContent: "space-between",
                      alignContent:"center",
                      alignItems:"center"

                    }}
                  >
                    <span
                      style={{
                        display: "block",
                        fontWeight: "600",
                        fontSize:"20px"
                      }}
                    >
                      Top 5 Electoral
                    </span>

                    <span
                      style={{
                        background: "#eee",
                        padding: "5px",
                        width: "100px",
                        textAlign: "center",
                        borderRadius: "6px",
                        display:"grid",
                        alignContent:"center",
                        alignItems:"center"
                      }}
                    >
                      View
                    </span>
                  </span>
                  <table style={{ width: "100%",padding:"0px" }} id="tableId">
                    <tbody>
                      <>
                        <tr>
                          <th>Name</th>
                          <th>Denomination(in Cr)</th>
                          <th>No of Purchase</th>

                        </tr>
                      </>

                      {Array.isArray(topfivedonor1) &&
                        topfivedonor1.slice(0, 5).map((item) => {
                          return (
                            <tr>
                              <td>{item.name}</td>
                              <td>{convertToCrore(item.denomination)}</td>
                              <td>{item.additionCounts}</td>

                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                </div>

                <div
                  style={{
                    padding: "0px",
                    boxShadow: "0px 0px 4px rgba(0,0,0,0.2)",
                    backgroundColor: "white",
                  }}
                  className="homePage_card_1"
                >
                  <PieChartsApex data={topfivedonor1}/>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default Home
