// src/Login.js
import React, { useState, useEffect } from 'react';
import Home from '../pages/Home';
import bgimage from "../assests/img/9.png";
import logo from "../assests/img/logo6.png";
import icon from "../assests/img/Mars-icons.png";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "bootstrap/dist/css/bootstrap.min.css";
import Alert from '@mui/material/Alert';
import axios from 'axios';
import { Button } from '@mui/material';


const Login = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState('');
  const [age, setAge] = useState('');

  const [LoginUsername, setLoginUsername] = useState('');
  const [Loginpassword, setLoginpassword] = useState('');

  const [isauth, setisauth] = useState(localStorage.getItem("Auth") || false);
  const [open, setOpen] = useState(false);
  const [RegisterLoading, setRegisterLoading] = useState(false);
  const [SigninLoading, setSigninLoading] = useState(false);

  const [Loginerror, setLoginerror] = useState(false);
  const [Registererror, setRegistererror] = useState(false);

  const [errorMessage, seterrorMessage] = useState("This is a warning Alert.");
  const [errorType, seterrorType] = useState("warning");


  const handleLogin = async (event) => {
    event.preventDefault();
    setSigninLoading(true)
    try {
      if (LoginUsername !== "" && Loginpassword !== "") {
        const data = {
          email: LoginUsername.toLowerCase(),
          password: Loginpassword
        };
  
        // const url = `${process.env.PUBLIC_URL}/api/login`;
        const url = `https://techinfoserver.onrender.com/api/login`;

        
        const response = await axios.post(url, data);
  
        if (response.status === 200) {
          console.log(response);
          const message = response.data.message;
          const auth = response.data.Auth

          console.log("Login successful", message);

          const techinfoCacheStorage = {
            authToken:response.data.token,
            email:response.data.email,
            auth :response.data.Auth,
            id:response.data.userId
          }
          localStorage.setItem('Auth',response.data.Auth)
          localStorage.setItem('techinfo-cache-storage',JSON.stringify(techinfoCacheStorage))
          setisauth(auth);
          setOpen(false);
          setSigninLoading(false)
          setLoginUsername("")
          setLoginpassword("")
    
        }
        if (response.status === 400) {
          const message = response.data.message;
          seterrorType("error");
          setLoginerror(true);
          seterrorMessage(message);
          setSigninLoading(false)
    
        }
        if (response.status === 500) {
          const message = response.data.message;
          seterrorType("error");
          setLoginerror(true);
          seterrorMessage(message);
          setSigninLoading(false)
    
        }
      } else {
        seterrorType("warning");
        setLoginerror(true);
        seterrorMessage("Email and Password cannot be empty");
        setSigninLoading(false)
        

      }
    } catch (error) {
      console.log(error);
      seterrorType("error");
      setLoginerror(true);
      const errMessage = error.response;
      const errorRes = errMessage.data.message;
      console.log(errMessage);
      seterrorMessage(errorRes);
      setSigninLoading(false)
      setLoginUsername("")
      setLoginpassword("")
}
  };

  const handleRegister = async () => {
    setRegisterLoading(true)
    try {
      if (username !== "" && password !== "" && email !=="" && country !== "" && age !== "" && gender !== "" ) {
        const data = {
          name:username.toLowerCase(),
          email: email.toLowerCase(),
          password: password,
          subscription:"Member",
          age:age,
          gender:gender,
          country:country


        };
  
        const url = `https://techinfoserver.onrender.com/api/register`;
  
        const response = await axios.post(url, data);
  
        console.log(response)
        if (response.status === 200) {
          console.log(response);
          const message = response.data.message;
          console.log("Registered successfully", message);
          setRegisterLoading(false)
          setRegistererror(true);
          setGender("")
          setAge("")
          setCountry("")
          setUsername("")
          setEmail("")
          setPassword("")
          seterrorType("success");
          seterrorMessage("Successfully Registered");
          setTimeout(() => {
            seterrorType("")
            seterrorMessage("");
            setRegistererror(false);

          }, 5000);


    
        }
      
       
      } else {
        seterrorType("warning");
        setRegistererror(true);
        seterrorMessage("Field cannot be empty");
        setRegisterLoading(false)
        

      }
    } catch (error) {
      console.log(error);
      seterrorType("error");
      setRegistererror(true);
      const errMessage = error.response;
      console.log(errMessage)
      const errorRes = errMessage.data.error;
      console.log(errMessage);
      seterrorMessage(errorRes);
      setRegisterLoading(false)
      setUsername("")
      setPassword("")
      setEmail("")
      setGender("")
      setAge("")
      setCountry("")

    }
  };

  const registerPopup = () =>{
    setOpen(!open)
    setUsername("")
    setEmail("")
    setPassword("")
    setGender("")
    setAge("")
    setCountry("")
    setRegistererror(false)
  }

  
  
 

  return (
    <div id="loginNewID">
      {isauth && (
        <div className="Buttons">
          <Home />
        </div>
      )}

      {!isauth && (
        <>
          <div className="Buttons">
            <div style={{ textAlign: "center" }} className="loginPage">
              <div className="login_body_content">
                <div className="bg-white dark:bg-gray-900 bgColor">
                  <div className="login_body_grid">
                    <div
                      style={{
                        backgroundImage: `linear-gradient(rgba(47,46,53,0.7), rgba(47, 46, 53,0.7)),url(${bgimage})`,
                      }}
                      className="hidden bg-cover lg:block lg:w-2/3 login_bg_image"
                    >
                      <div className="login_info">
                        <div
                          style={{
                            position: "absolute",
                            top: "30px",
                            left: "30px",
                          }}
                        >
                          <img
                            style={{
                              background: "none",
                              mixBlendMode: "color-dodge",
                            }}
                            width={80}
                            src={logo}
                            alt="img"
                          />
                        </div>
                        <div>
                          <span
                            style={{
                              fontSize: "36px",
                              textTransform: "uppercase",
                              fontWeight: "700",
                              color: "white",
                              display: "inline",
                            }}
                            className="text-4xl font-bold text-center text-gray-700 dark:text-white MarsLogo"
                          >
                            Tech<span style={{ color: "#0d6efd" }}>Info</span>
                          </span>

                          <p className="left_grid_text">
                            Welcome to the TechInfo. Access to this system is
                            limited - please contact{" "}
                            <a
                              rel="noreferrer"
                              target={"_blank"}
                              style={{
                                color: "black",
                                textDecoration: "none",
                                fontSize: "16px",
                                fontWeight: "700",
                              }}
                              href="mailto:nabasundar.rout@gmail.com"
                            >
                              <span style={{ color: "#0d6efd" }}>
                                nabasundar.rout@gmail.com
                              </span>
                            </a>{" "}
                            for details.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="right_grid_align bgColor">
                      <div className="flex-1">
                        <div className="text-center">
                          <span
                            style={{
                              fontSize: "36px",
                              textTransform: "uppercase",
                              fontWeight: "700",
                              color: "white",
                              display: "inline",
                            }}
                            className="text-4xl font-bold text-center text-gray-700 dark:text-white MarsLogo"
                          >
                            Tech<span style={{ color: "#0d6efd" }}>Info</span>
                          </span>

                          <p
                            style={{
                              paddingTop: "0px",
                              marginBottom: "15px",
                              display: "block",
                            }}
                            className="left_grid_text left_margin loginText1 formLableText1"
                          >
                            Sign in to access your account
                          </p>
                        </div>

                        <form
                          class="row g-3 needs-validation"
                          onSubmit={handleLogin}
                          novalidate
                        >
                          <div
                            style={{
                              display: "block",
                              width: "70%",
                              margin: "auto",
                            }}
                          >
                            <div>
                              {Loginerror && (
                                <div style={{ paddingBottom: "10px" }}>
                                  <Alert severity={errorType}>
                                    {errorMessage}
                                  </Alert>
                                </div>
                              )}

                         

                              
                            </div>
                            <div style={{ display: "grid", gap: "20px" }}>
                              <div>
                                <label
                                  for="validationCustom01"
                                  class="form-label"
                                >
                                  Enter your email
                                </label>
                                <input
                                  className="loginPage_new_form_input"
                                  placeholder="Enter your email"
                                  type="email"
                                  value={LoginUsername}
                                  onChange={(e) =>
                                    setLoginUsername(e.target.value)
                                  }
                                  required
                                />
                              </div>

                              <div>
                                <label
                                  for="validationCustom02"
                                  class="form-label"
                                >
                                  Enter your Password
                                </label>
                                <input
                                  className="loginPage_new_form_input"
                                  type="password"
                                  placeholder="Enter your password"
                                  value={Loginpassword}
                                  onChange={(e) =>
                                    setLoginpassword(e.target.value)
                                  }
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          <div style={{ marginTop: "30px" }}>
                            <button
                              class="btn btn-primary btn_login"
                              type="submit"
                              disabled={SigninLoading}
                            >
                              {SigninLoading ? (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span style={{ paddingLeft: "10px" }}>
                                    Verifying...
                                  </span>
                                </>
                              ) : (
                                "Sign in"
                              )}
                            </button>
                          </div>
                        </form>

                        <div
                          style={{
                            width: "70%",
                            paddingTop: "1rem",
                            display: "block",
                            margin: "auto",
                          }}
                        >
                          <span
                            id="Login_herf"
                            style={{
                              color: "white",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              className="loginText1 formLableText1"
                              style={{
                                textDecoration: "none",
                                color: "white",
                                cursor: "pointer",
                              }}
                              onClick={() => setOpen(!open)}
                            >
                              Don't have Account?
                            </span>

                            <a
                              rel="noreferrer"
                              target={"_blank"}
                              className="loginText1 formLableText1"
                              style={{ textDecoration: "none", color: "white" }}
                              href="mailto:nabasundar.rout@gmail.com?subject=Need Help On Sign in On Lab Co-pilot Too Applications!"
                            >
                              {" "}
                              Need Help?
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Dialog
        open={open}
        onClose={registerPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-description-new1"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="text-center">
            <span className="text-4xl font-bold text-center text-gray-700 dark:text-white MarsLogo">
              <span
                style={{
                  fontSize: "26px",
                  textTransform: "uppercase",
                  fontWeight: "700",
                  color: "#23232c",
                  display: "inline",
                }}
                className="text-4xl font-bold text-center text-gray-700 dark:text-white MarsLogo"
              >
                Tech<span style={{ color: "#0d6efd" }}>Info</span>
              </span>
            </span>

            <p
              style={{
                paddingBottom: "0px !important",
                fontSize: "18px",
                color: "#23232c !important",
                paddingTop: "10px",
              }}
            >
              Register here to access your account
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <>
            <div>
              {Registererror && (
                <div style={{ paddingBottom: "10px" }}>
                  <Alert severity={errorType}>{errorMessage}</Alert>
                </div>
              )}
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "49% 49%",
                gap: "1rem",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <div className="loginPage_new_form">
                <label className="loginPage_new_form_label">Name</label>
                <input
                  className="loginPage_new_form_input"
                  placeholder="Enter your Name"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>

              <div className="loginPage_new_form">
                <label className="loginPage_new_form_label">Email</label>
                <input
                  className="loginPage_new_form_input"
                  placeholder="Enter your email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div className="loginPage_new_form">
                <label className="loginPage_new_form_label">Country</label>
                <input
                  className="loginPage_new_form_input"
                  placeholder="Enter your Country"
                  type="text"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  required
                />
              </div>

              <div className="loginPage_new_form">
                <label className="loginPage_new_form_label">Age</label>
                <input
                  className="loginPage_new_form_input"
                  placeholder="Enter your Age"
                  type="number"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  required
                />
              </div>

              <div className="loginPage_new_form">
                <label className="loginPage_new_form_label">Gender</label>
                <select
                  className="loginPage_new_form_input"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  required
                >
                  <option value="">Select your gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div className="loginPage_new_form">
                <label className="loginPage_new_form_label">New Password</label>
                <input
                  className="loginPage_new_form_input"
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            </div>
          </>
        </DialogContent>
        <DialogActions>
            <div style={{display:"grid",justifyContent:"flex-end"}}>
            <div style={{width:"100%", padding:"10px 18px"}} className="new-login-btn-alignment">
            <Button
              style={{ width: "30%" }}
              disabled={RegisterLoading}
              className="submit_btn_new-login"
              onClick={handleRegister}
            >
              {RegisterLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span style={{ paddingLeft: "10px" }}>Registering...</span>
                </>
              ) : (
                "Register"
              )}
            </Button>

            <span
              style={{
                fontSize: "12px",
                background: "#fff4e5",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              Please note that access to the Techinfo Tool is restricted.
              Contact nabasundar.rout@gmail.com for any access-related inquiries
              or requests.
            </span>
          </div>
            </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Login;
