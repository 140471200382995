import React, { useEffect, useState } from "react";
import MainLayout from "../nav/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import { FaDownload, FaUndo } from "react-icons/fa";
import GenerateExcel from "../layout/GenerateExcel";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import DonorListBar from "../chats/DonorListBar";
import DonorLineChats from "../chats/DonorLineChats";
import MyDateRangePickerComponent from "../chats/MyDateRangePickerComponentEB";
import userInfo from "../../EB.json";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const ElecList = () => {
  const [filtergender, setfiltergender] = useState("");
  const [filtercountry, setfiltercountry] = useState("");
  const [currentPagelimit, setPageLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [purchaserDetails, setPurchaserDetails] = useState({});
  const [jsonData, setjsonData] = useState(userInfo.filter(obj => !obj["Sr No."].startsWith("Page")))

  const [query, setQuery] = useState("");

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  

  useEffect(() => {
    // Parse the JSON data

    // Initialize an object to store unique purchaser names and their total denominations
    const details = {};
    const additionCounts = {}; // Object to track how many times denomination is added

    // Iterate over the JSON data to calculate total denominations for each purchaser
    jsonData.forEach((item) => {
        const purchaserName = item["Name of the Political Party"];
        const denomination = parseInt(item["Denomination"].replace(/,/g, "")); // Remove commas and convert to integer
        if (details[purchaserName]) {
            details[purchaserName].denomination += denomination;
            additionCounts[purchaserName] = (additionCounts[purchaserName] || 0) + 1;
        } else {
            details[purchaserName] = {
                date: item["Encashment"],
                denomination: denomination,
                name: purchaserName,
                count: 0 // Initialize count to 0 for new purchaser
            };
            additionCounts[purchaserName] = 1; // Initialize addition count to 1 for new purchaser
        }
    });

    // Update the count property with the correct count value
    for (const purchaserName in details) {
        details[purchaserName].count = additionCounts[purchaserName];
    }

    // Update state with the calculated purchaser details
    setPurchaserDetails(details);
}, []);


  const data = jsonData === null ? " " : Object.values(jsonData);
  const data1 =
    purchaserDetails === null ? " " : Object.values(purchaserDetails);

  //   console.log(data1)

  const search_parameters = ["Name of the Political Party"];
  const search_parameters1 = ["name"];

  const filter_gender = [
    ...new Set(data.map((item) => item["Encashment"])),
  ];
  const filter_country = [...new Set(data.map((item) => item["Denomination"]))];

  const filter_date = [...new Set(data1.map((item) => item.date))];
  
  const filter_denomination = [
    ...new Set(data1.map((item) => item.denomination)),
  ];

  let filteredData = [];
  let filteredData1 = [];

  if (jsonData !== null) {
    filteredData = jsonData.filter((obj) => {
      return obj;
    });
  }

  function search(sortedAsc) {
    return sortedAsc.filter(
      (item) =>
        item["Encashment"].includes(filtergender) &&
        item["Denomination"].includes(filtercountry) &&
        search_parameters.some((parameter) =>
          item[parameter].toString().toLowerCase().includes(query.toLowerCase())
        )
    );
  }

  function details(data1) {
    return data1.filter(
      (item) =>
        item.date.includes(filtergender) &&
        search_parameters1.some((parameter) =>
          item[parameter].toString().toLowerCase().includes(query.toLowerCase())
        )
    );
  }

  let NUM_OF_RECORDS = search(filteredData).length;
  if (NUM_OF_RECORDS <= currentPagelimit) {
    $(".pagination").hide(true);
  } else {
    $(".pagination").show(true);
  }

  let NUM_OF_RECORDS_Purc = details(data1).length;
  if (NUM_OF_RECORDS_Purc <= currentPagelimit) {
    $(".pagination").hide(true);
  } else {
    $(".pagination").show(true);
  }

  const currentData = search(filteredData).slice(
    (currentPage - 1) * currentPagelimit,
    (currentPage - 1) * currentPagelimit + currentPagelimit
  );

  const currentData__Purc = details(data1).slice(
    (currentPage - 1) * currentPagelimit,
    (currentPage - 1) * currentPagelimit + currentPagelimit
  );

  
  const pageCount = Math.ceil(search(filteredData).length / currentPagelimit);
  const pageCount1 = Math.ceil(details(data1).length / currentPagelimit);

  const handelepageclick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const resetData = () => {
    setQuery("");
    setfiltercountry("");
    setfiltergender("");
    setCurrentPage(1);
  };

  const resetData1 = () => {
    setQuery("");
    setfiltercountry("");
    setfiltergender("");
    setCurrentPage(1);
  };


  return (
    <div>
      <Box
        className="mainLayout"
        sx={{ display: "flex", overflow: "hidden", overflowY: "auto" }}
      >
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div id="mainHome">
            <div style={{ display: "grid", gap: "1rem" }}>
              <div
                style={{
                  fontSize: "28px",
                  fontWeight: "600",
                  paddingLeft: "5px",
                  paddingBottom: "1rem",
                  paddingTop: "10px",
                }}
              >
                Electoral bonds
              </div>

              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="List Of Bonds" {...a11yProps(0)} />
                    <Tab label="Purchase Details" {...a11yProps(1)} />
                    <Tab label="Analytics" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <CustomTabPanel id="ListOfBonds" value={value} index={0}>
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                        padding: "5px",
                      }}
                    >
                      <div className="search-wrapper">
                        <input
                          type="search"
                          name="search-form"
                          id="search-form"
                          value={query}
                          className="SearchBox_newworkflow"
                          placeholder="Search for Name"
                          autoComplete="off"
                          onChange={(e) => {
                            setQuery(e.target.value);
                            handlePageChange({ selected: 1 });
                          }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="selectParent select">
                          <select
                            value={filtergender}
                            onChange={(e) => {
                              setfiltergender(e.target.value);
                              handlePageChange({ selected: 1 });
                            }}
                            className="custom-select custom-selectworkflow"
                            aria-label="Filter Residual"
                          >
                            <option value="">Date of Purchase</option>
                            {filter_gender.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <span className="focus"></span>
                        </div>

                        <div className="selectParent select">
                          <select
                            value={filtercountry}
                            onChange={(e) => {
                              setfiltercountry(e.target.value);
                            }}
                            className="custom-select custom-selectworkflow"
                            aria-label="Filter Residual"
                          >
                            <option value="">Denomination</option>
                            {filter_country.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <span className="focus"></span>
                        </div>
                        <button
                          onClick={resetData}
                          title="Reset"
                          style={{
                            width: "50px",
                            height: "45px",
                            paddingLeft: "14px",
                            paddingRight: "14px",
                            borderRadius: "6px",
                            borderWidth: "1px",
                          }}
                        >
                          <FaUndo />
                        </button>
                        <GenerateExcel
                          excelData={currentData}
                          fileName={"UserList"}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "5px",
                        paddingTop: "20px",

                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontSize: "18px", fontWeight: "700" }}>
                        Total number of donor List: {search(jsonData).length}
                      </span>

                      <div>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handelepageclick}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                          forcePage={currentPage - 1}
                        />
                      </div>
                    </div>

                    <table id="userTableinfo">
                      <thead>
                        <tr>
                          <th style={{ width: "40%" }}>Purchaser Name</th>
                          <th style={{ width: "auto" }}>Date of Purchase</th>
                          <th style={{ width: "auto", textAlign: "center" }}>
                            Denomination(in crore)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td>{user["Name of the Political Party"]}</td>
                              <td>
                                <span className="emailDiv">
                                  {user["Encashment"]}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {user.Denomination}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "5px",
                      }}
                    >
                      <div>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handelepageclick}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                          forcePage={currentPage - 1}
                        />
                      </div>
                    </div>
                  </>
                </CustomTabPanel>
                <CustomTabPanel id="ListOfBonds" value={value} index={1}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                        padding: "5px",
                      }}
                    >
                      <div className="search-wrapper">
                        <input
                          type="search"
                          name="search-form"
                          id="search-form"
                          value={query}
                          className="SearchBox_newworkflow"
                          placeholder="Search for Name, Denomination"
                          autoComplete="off"
                          onChange={(e) => {
                            setQuery(e.target.value);
                            handlePageChange({ selected: 1 });
                          }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="selectParent select">
                          <select
                            value={filtergender}
                            onChange={(e) => {
                              setfiltergender(e.target.value);
                              handlePageChange({ selected: 1 });
                            }}
                            className="custom-select custom-selectworkflow"
                            aria-label="Filter Residual"
                          >
                            <option value="">Date of Purchase</option>
                            {filter_date.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <span className="focus"></span>
                        </div>

                        <div className="selectParent select">
                          <select
                            value={filtercountry}
                            onChange={(e) => {
                              setfiltercountry(e.target.value);
                            }}
                            className="custom-select custom-selectworkflow"
                            aria-label="Filter Residual"
                          >
                            <option value="">Denomination</option>
                            {filter_denomination.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <span className="focus"></span>
                        </div>
                        <button
                          onClick={resetData1}
                          title="Reset"
                          style={{
                            width: "50px",
                            height: "45px",
                            paddingLeft: "14px",
                            paddingRight: "14px",
                            borderRadius: "6px",
                            borderWidth: "1px",
                          }}
                        >
                          <FaUndo />
                        </button>
                        <GenerateExcel
                          excelData={currentData}
                          fileName={"UserList"}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "5px",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontSize: "18px", fontWeight: "700" }}>
                        Total number of purchase are: {details(data1).length}
                      </span>

                      <div>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={pageCount1}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handelepageclick}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                          forcePage={currentPage - 1}
                        />
                      </div>
                    </div>

                    <table id="userTableinfo">
                      <thead>
                        <tr>
                          <th style={{ width: "40%" }}>Purchaser Name</th>
                          <th style={{ width: "auto" }}>
                            Last Date of Purchase
                          </th>
                          <th style={{ width: "auto" }}>Denomination</th>
                          <th style={{ width: "auto" }}>No of Purchase </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData__Purc.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>{item.date}</td>
                              <td>{item.denomination}</td>
                              <td>{item.count}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <div style={{ display: "grid", gap: "1rem" }}>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "49% 50%",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#333",
                          padding: "1rem",
                          borderRadius: "10px",
                        }}
                      >
                        <DonorListBar data={details(data1)} />
                      </div>
                      <div
                        style={{
                          backgroundColor: "#333",
                          padding: "1rem",
                          borderRadius: "10px",
                        }}
                      >
                        <DonorLineChats data={data1} />
                      </div>

                     
                    </div>

                    <div>
                      <MyDateRangePickerComponent />
                    </div>
                  </div>
                </CustomTabPanel>
              </Box>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default ElecList;
