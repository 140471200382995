import React, { useState,useEffect } from 'react'
import SideNavbar from '../nav/SideNavbar'
import Box from '@mui/material/Box';

const MainLayout = () => {

 
//   const [authStatus, setAuthStatus] = useState(null);
//   const [techinfoCacheStorag, settechinfoCacheStoragee] = useState(localStorage.getItem('techinfo-cache-storage'))

//   const techinfoCacheStorage = JSON.parse(techinfoCacheStorag);
//   const userId = techinfoCacheStorage.id;

// // Now 'id' contains the value you stored in localStorage
// console.log(userId);


//   useEffect(() => {
//     const fetchAuthStatus = async () => {
//       try {
//         const response = await axios.get(`https://techinfoserver.onrender.com/api/getAuth/${userId}`);
//         setAuthStatus(response.data.authStatus);
//       } catch (error) {
//         console.error('Error fetching authentication status:', error);
//       }
//     };

//     fetchAuthStatus();

//     // Cleanup function to prevent memory leaks
//     return () => {
//       // Cleanup logic if needed
//     };
//   }, [userId]); 


  return (
    <Box>
        <SideNavbar/>
    </Box>
  )
}

export default MainLayout
