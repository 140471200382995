import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/auth/Login';
import DonorList from './components/pages/DonorList';
import ElecList from './components/pages/ElecList';

const App = () => {
  // localStorage.setItem("Auth",true)
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/donor" element={<DonorList />} />
          <Route path="/electoral" element={<ElecList />} />

        </Routes>
      </Router>
    </div>
  );
};

export default App;
