import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Title,
  Chart,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, BarElement, LinearScale, Tooltip, Title);

const VericalBarCharts = () => {
  const chartRef = useRef(null);

 
  const DataItem = [
    {
      2022: {
        High: 4,
      },
      quarter: "Jan",
    },
    {
      2022: {
        High: 2,
      },
      quarter: "Feb",
    },
    {
      2022: {
        High: 5,
      },
      quarter: "Mar",
    },
    {
      2022: {
        High: 2,
      },
      quarter: "Dec",
    },
    {
      2023: {
        High: 8,
      },
      quarter: "Jan",
    },
  ];

 const YearValue = ['2022','2023','2024']

  var data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "High Risk Vendor ",
        data: DataItem,
        backgroundColor: "#e3a9a9",
        borderColor: "#e3a9a9",
        borderWidth: 0,
        borderRadius: 10,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    parsing: {
      xAxisKey: "quarter",
      yAxisKey: `${YearValue[0]}.High`,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#253978",
          stepSize: 2,
        },
      },
      y: {
        grid: {
          display: true,
          drawBorder: false, // <-- this removes y-axis line
          lineWidth: 0.3,
        },
        ticks: {
          color: "#253978",
          stepSize: 2,
        },
      },
    },
    plugins: {
      tooltips: {
        enabled: true,
      },
      title: {
        display: true,
        padding: {
          top: 0,
          bottom: 10,
        },
        font: {
          size: 20,
        },
      },
    },
    indexAxis: "x",
    legend: {
      display: false,
    },
  };

  const ChangeTheData = () => {
    const year = document.getElementById("year").value;
    options.parsing.yAxisKey = `${year}.High`;
    const chartItem = chartRef.current;
    chartItem.update();
  };

  /*
       const ChangeTheData = () =>{
        const year = document.getElementById('year').value;
        const risk = document.getElementById('risk').value;
        options.parsing.yAxisKey = `${year}.${risk}`;
        const chartItem = chartRef.current;
        chartItem.update()


      }
       <select style={{display:"none"}} className="SelectItems" id="risk" onChange={ChangeTheData}>
            <option value="High">High</option>
          </select>
      */

  return (
    <div style={{padding:"10px"}} className='pieChatsDiv'>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        className="GraphText"
      >
        <div style={{ gridGap: "10px" }}>
          <select className="SelectItems" id="year" onChange={ChangeTheData}>
            {YearValue.map((year,index) =>{
              return(
                <option key={index} value={year}>{year}</option>
              )
            })}
          </select>
        </div>
      </div>

      <div style={{ height: "275px" }}>
        <Bar
          ref={chartRef}
          id="myChart"
          data={data}
          height="275px"
          options={options}
        />
      </div>
    </div>
  );
};

export default VericalBarCharts;
