import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import axios from "axios";


const PieChartsApex = (props) => {
  const [chartsData , setChartsdata] = useState([])
  const [isloading , setIsloading] = useState(false)
  function convertToCrore(number) {
    // Check if the input is a valid number
    if (typeof number !== 'number' || isNaN(number)) {
        return "Invalid input. Please provide a valid number.";
    }

    // Convert the number to crore
    const crore = (number / 10000000);

    // Return the result
    return parseFloat(crore.toFixed(2));;
}


 const data = props.data


 const denominations = data !==undefined ? data.map(party => party.denomination) : [];
 const Catedenominations = data !==undefined ? data.map(party => party.name) : [];


//  console.log(Catedenominations);
  



  const options1 = {
    series:denominations,
    labels:Catedenominations,
    chart: {
      events: {
        animationEnd: undefined,
        beforeMount: undefined,
        mounted: undefined,
        updated: undefined,
        mouseMove: undefined,
        mouseLeave: undefined,
        click: function(event, chartContext, config) {
          // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
          console.log("hi")
          console.log(config)


        },
        legendClick: undefined,
        markerClick: undefined,
        xAxisLabelClick: undefined,
        selection: undefined,
        dataPointSelection: undefined,
        dataPointMouseEnter: undefined,
        dataPointMouseLeave: undefined,
        beforeZoom: undefined,
        beforeResetZoom: undefined,
        zoomed: undefined,
        scrolled: undefined,
        scrolled: undefined,
      }
  },
    dataLabels: {
        enabled: false
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'bottom',
        horizontalAlign: 'center', 
        floating: false,
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 400,
        formatter: undefined,
        inverseOrder: false,
        width: undefined,
        height: undefined,
        tooltipHoverFormatter: undefined,
        customLegendItems: [],
        offsetX: 0,
        offsetY: 0,
        labels: {
            colors: undefined,
            useSeriesColors: false
        },
        markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
        },
        itemMargin: {
            horizontal: 5,
            vertical: 0
        },
        onItemClick: {
            toggleDataSeries: true
        },
        onItemHover: {
            highlightDataSeries: true
        },
    },

    plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          expandOnClick: true,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10,
              enabled: false
          }, 
          donut: {
            size: '86%',
            background: 'transparent',
            labels: {
              show: true,
             
              value: {
                show: true,
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                color: undefined,
                offsetY: 16,
                formatter: function (val) {
                  return val
                }
              },
              total: {
                show: true,
                showAlways: false,
                label: 'Denomination(cr)',
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: '#253978',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return convertToCrore(a + b) 
                  }, 0)
                }
              }
            }
          },      
        }
    }
    
  }


  return (
    <div style={{padding:"10px"}} className='pieChatsDiv'>
      {isloading && (
        <div width="400px" style={{height:"300px",justifyContent:"center",alignItems:"center",alignContent:"center",display:"grid"}}>Please wait</div>
      )}

      {!isloading &&(
        <Chart options={options1} series={options1.series} height="400px" type="donut"/>
      )}
    </div>
  )
}

export default PieChartsApex
