import React, { useState, useEffect } from "react";
import doc1 from "../assests/img/11.png";
import doc2 from "../assests/img/12.png";
import doc3 from "../assests/img/13.png";
import { Link } from "react-router-dom";

function Slideshow() {
  const [slideIndex, setSlideIndex] = useState(1);

  useEffect(() => {
    showSlides(slideIndex);

    // Automatic slideshow every 5000 milliseconds
    const interval = setInterval(() => {
      plusSlides(1);
    }, 5000);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, [slideIndex]);

  function plusSlides(n) {
    setSlideIndex((prevIndex) => {
      let newIndex = prevIndex + n;
      if (newIndex > 3) {
        newIndex = 1;
      } else if (newIndex < 1) {
        newIndex = 3;
      }
      return newIndex;
    });
  }

  function currentSlide(n) {
    setSlideIndex(n);
  }

  // function showSlides(n) {
  //   let i;
  //   const slides = document.getElementsByClassName("mySlides");
  //   const dots = document.getElementsByClassName("dot");
  //   if (n > slides.length) { setSlideIndex(1); }
  //   if (n < 1) { setSlideIndex(slides.length); }
  //   for (i = 0; i < slides.length; i++) {
  //     slides[i].style.display = "none";
  //   }
  //   for (i = 0; i < dots.length; i++) {
  //     dots[i].className = dots[i].className.replace("active1", "");
  //   }
  //   slides[slideIndex - 1].style.display = "block";
  //   dots[slideIndex - 1].className += " active1";
  // }

  function showSlides(n) {
    const slides = document.getElementsByClassName("mySlides");
    const dots = document.getElementsByClassName("dot");

    if (n < 1) {
      setSlideIndex(slides.length);
      return;
    } else if (n > slides.length) {
      setSlideIndex(1);
      return;
    }

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    for (let i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active1", "");
    }

    slides[n - 1].style.display = "block";
    dots[n - 1].className += " active1";
  }

  return (
    <div style={{ position: "relative" }} className="slideshow-container">
      <div className="mySlides">
        <div className="item">
          <div className="item-div-1">
            <div style={{}}>
              <span className="item_header">Analysis of Electoral Bonds​</span>
            </div>

            <div
              style={{
                marginTop: "10px",
              }}
            >
              <p>
                Electoral bonds offer potential transparency in political
                funding but face criticism for their lack of accountability and
                potential for money laundering.
              </p>
            </div>

            <div style={{ marginTop: "10px" }} className="box-cls-2">
              <Link to={`/electoral?analysis`}>
                <button
                  style={{ background: "rgb(13, 110, 253)" }}
                  className="btn_go_now"
                >
                  Go Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="mySlides">
        <div className="item">
          <div className="item-div-1">
            <div style={{}}>
              <span className="item_header">Analysis of Donor</span>
            </div>

            <div
              style={{
                marginTop: "10px",
              }}
            >
              <p>
                The analysis of electoral bonds highlights their potential to
                introduce transparency in political funding{" "}
              </p>
            </div>

            <div style={{ marginTop: "10px" }} className="box-cls-2">
              <Link to={`/Donor`}>
                <button
                  style={{ background: "rgb(13, 110, 253)" }}
                  className="btn_go_now"
                >
                  Go Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="mySlides">
        <div className="item">
          <div className="item-div-1">
            <div style={{}}>
              <span className="item_header">Donor Report</span>
            </div>

            <div
              style={{
                marginTop: "10px",
              }}
            >
              <p>
                Donors utilize electoral bonds to contribute to political
                parties while maintaining anonymity, raising concerns about
                accountability and transparency.{" "}
              </p>
            </div>

            <div style={{ marginTop: "10px" }} className="box-cls-2">
              <Link to={`/Donor?analysis`}>
                <button
                  style={{ background: "rgb(13, 110, 253)" }}
                  className="btn_go_now"
                >
                  Go Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="prev" onClick={() => plusSlides(-1)}>
        ❮
      </div>
      <div className="next" onClick={() => plusSlides(1)}>
        ❯
      </div>

      <div
        style={{
          textAlign: "center",
          position: "absolute",
          top: "10px",
          left: "10px",
        }}
      >
        <span className="dot" onClick={() => currentSlide(1)}></span>
        <span className="dot" onClick={() => currentSlide(2)}></span>
        <span className="dot" onClick={() => currentSlide(3)}></span>
      </div>
    </div>
  );
}

export default Slideshow;
