import * as React from "react";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import { white } from "@mui/material/colors";
import { Divider } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

import AutoModeIcon from '@mui/icons-material/AutoMode';
import ErrorIcon from '@mui/icons-material/Error';
import GroupsIcon from '@mui/icons-material/Groups';
import AssignmentIcon from '@mui/icons-material/Assignment';
export default function CustomCards(props) {
  // console.log(props.count)
 const isdown = props.count !== null ? props.count > 0 ? true : false : undefined

//  console.log("isdown")
//  console.log(isdown)


  return (
    <Card sx={{ maxWidth: 345 }}>
      <div
        style={{
          display: "flex",
          gap: "10px",
          padding: "1rem",
          alignContent: "baseline",
          alignItems: "flex-start",
        }}
      >
        <div style={{ paddingTop: "6px" }}>
          <Avatar
            sx={{
              boxShadow: "0px 0px 4px rgba(0,0,0,0.8)",
              backgroundColor: "white",
            }}
            aria-label="recipe"
          >
            {props.title === "Total Donor" ? (
              <GroupsIcon sx={{ color: "#333" }} />
            ) : props.title === "Electoral Bonds" ? (
              <GroupsIcon sx={{ color: "#333" }} />
            ) : props.title === "Denomination by Doner" ? (
              <AutoModeIcon sx={{ color: "#333" }} />
            ) : props.title === "Denomination by Electoral" ? (
              <AutoModeIcon sx={{ color: "#333" }} />
            ) : (
              <AutoModeIcon sx={{ color: "#333" }} />
            )}
          </Avatar>
        </div>

        <div style={{ display: "grid", gap: "5px" }}>
          <span
            style={{
              fontSize: "20px",
              color: "gray",
            }}
          >
            {props.title}
          </span>
          <span
            style={{
              fontWeight: "600",
              fontSize: "22px",
              color: "3d3d3d",
            }}
            className={`${props.className}`}
          >
            {props.number}
          </span>
        </div>
      </div>
      {isdown !== undefined && props.title !== "No of Political Party" &&  (
        <>
          <Divider />

          <div style={{ padding: "1rem", display: "flex", gap: "10px" }}>
        {!isdown && isdown !== undefined && props.title !== "No of Political Party" &&  (
          <>
            <TrendingDownIcon sx={{ color: "red" }} />
            <span style={{ fontWeight: "600" }}>{props.count}</span>
          </>
        )}

        {isdown && isdown !== undefined && props.title !== "No of Political Party" && (
          <>
            <TrendingUpIcon sx={{ color: "green" }} />
            <span style={{ fontWeight: "600" }}>{props.count}</span>
          </>
        )}
      </div>
        </>
      )}

    
    </Card>
  );
}
