import React from 'react';
import ReactApexChart from 'react-apexcharts';

const LineChart = (props) => {
    const dataList = props.data
    // console.log(dataList)

    const denominationCounts = {};
    dataList.forEach(item => {
    const denomination = item.denomination;
    if (denominationCounts[denomination]) {
        denominationCounts[denomination]++;
    } else {
        denominationCounts[denomination] = 1;
    }
});

// Convert the counts into an array of objects
const denominationArray = [];
for (const denomination in denominationCounts) {
    denominationArray.push({ denomination: parseInt(denomination), count: denominationCounts[denomination] });
}

// Sort the array in decreasing order of count
denominationArray.sort((a, b) => b.count - a.count);

// Get the top 10 denominations
const top10Denominations = denominationArray.slice(0, 10);

const countArray = top10Denominations.map(item => item.count);

const countArraydenomination = top10Denominations.map(item => item.denomination);

  // Call the function
//   console.log( countArraydenomination);

  const options = {
    series: [{
      name: "Count Of Denominations",
      data: countArray
    }],
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false,
      
      
    },
    colors: ["#93C3EE", "#247BA0"],

    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: [ '#FDD835'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100]
      }
      },
    markers: {
        size: 4, // Size of the circle marker
        colors: '#ffffff', // Fill color of the circle marker
        strokeWidth: 2, // Border width of the circle marker
        strokeColors: '#E5C6A0', // Border color of the circle marker
        fillColors: '#93C3EE', // Fill color of the line (change to red)
  
      },
    stroke: {
        curve: 'smooth',
        dashArray: 3,
    },
   
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      categories: countArraydenomination,
      labels: {
        formatter: function (value) {
          // Convert large numbers to billions format (e.g., 1000000000 => 1b)
          if (value >= 1000000000) {
            return (value / 1000000000).toFixed(0) + " Bilion";
          }
          // Convert large numbers to millions format (e.g., 1000000 => 1m)
          else if (value >= 1000000) {
            return (value / 1000000).toFixed(0) + " Milion";
          }
          return value;
        },
        style: {
          colors: "#ffffff"
        },
      }
    },
    yaxis:{
      labels:{
        style: {
          colors: "#ffffff"
        }
      }
    }
  };

  return (
    <div>
      <ReactApexChart options={options} series={options.series} type="line" height={400} />
    </div>
  );
};

export default LineChart;
