import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Card } from 'react-bootstrap';
import donorPicker from '../../donorList.json'; // Sample data

function MyDateRangePickerComponent() {
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  });

  const [filterDataByDate, setFilterDataByDate] = useState([]);
  const [dateData, setDateData] = useState();
  const [jsonData, setjsonData] = useState(donorPicker.filter(obj => !obj["Sr No."].startsWith("Page")))

  const filterData = () => {
    const { startDate, endDate } = selectionRange;
    const adjustedStartDate = new Date(startDate);
    adjustedStartDate.setHours(0, 0, 0, 0);
    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    const filteredData = [];

    // Split data into batches of 100 items
    const batchSize = 100;
    for (let i = 0; i < jsonData.length; i += batchSize) {
      const batch = jsonData.slice(i, i + batchSize);
      const batchFilteredData = batch.filter(item => {
        const itemDate = new Date(item["Date of Purchase"].replace(/\/([a-zA-Z]+)\//, ' $1 '));
        setDateData(itemDate)
        return itemDate >= adjustedStartDate && itemDate <= adjustedEndDate;
      });
      filteredData.push(...batchFilteredData);
    }

    setFilterDataByDate(filteredData);
  };

  console.log(filterDataByDate)

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
    filterData();
  };

  let totalDenomination = 0;
let highestDenomination = 0;
let highestPurchaser = '';

filterDataByDate.forEach(entry => {
  const denomination = parseInt(entry['Denomination'].replace(/,/g, ''));
  totalDenomination += denomination;
  if (denomination > highestDenomination) {
      highestDenomination = denomination;
      highestPurchaser = entry['Purchaser Name'];
  }
});

const purchaseCountByPurchaser = {};

// Loop through the data and count purchases by each purchaser
filterDataByDate.forEach(entry => {
    const purchaser = entry['Purchaser Name'];
    if (purchaseCountByPurchaser[purchaser]) {
        purchaseCountByPurchaser[purchaser]++;
    } else {
        purchaseCountByPurchaser[purchaser] = 1;
    }
});

// Find the purchaser with the highest count
let highestCount = 0;
let highestPurchaser1 = '';

for (const purchaser in purchaseCountByPurchaser) {
    if (purchaseCountByPurchaser.hasOwnProperty(purchaser)) {
        const count = purchaseCountByPurchaser[purchaser];
        if (count > highestCount) {
            highestCount = count;
            highestPurchaser1 = purchaser;
        }
    }
}

console.log("Purchaser with the highest count:", highestPurchaser1);
console.log("Highest count:", highestCount);

  return (
    <div style={{background:"#eee",padding:"1rem",borderRadius:"6px"}}>
      <div style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
        <div style={{ width: "45%" }}>
          <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
        </div>

        <div style={{ width: "53%" }}>
            <div style={{display:"grid",gridTemplateColumns:"45% 45%",gap:"1rem",padding:"1rem"}}>
            <Card sx={{ maxWidth: 345 }}>
            <div style={{ position: "relative" }}>
              <div style={{ padding: "10px" }}>
                <div style={{ display: "grid" }}>
                  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                    Total Denomination
                  </span>
                  <span style={{ fontWeight: "700", fontSize: "22px" }}>
                   {totalDenomination}
                  </span>
                 
                  <span style={{ fontWeight: "normal", fontSize: "14px" }}>
                    Created By: Nabasundar
                  </span>
               
                </div>
              </div>
            </div>
          </Card>

          <Card sx={{ maxWidth: 345 }}>
            <div style={{ position: "relative" }}>
              <div style={{ padding: "10px" }}>
                <div style={{ display: "grid" }}>
                  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                    Heigest Denomination
                  </span>
                  <span style={{ fontWeight: "700", fontSize: "22px" }}>
                   {highestDenomination}
                  </span>
                  
                  <span style={{ fontWeight: "normal", fontSize: "14px" }}>
                    {highestPurchaser}
                  </span>
               
                </div>
              </div>
            </div>
          </Card>

          <Card sx={{ maxWidth: 345 }}>
            <div style={{ position: "relative" }}>
              <div style={{ padding: "10px" }}>
                <div style={{ display: "grid" }}>
                  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                  Purchaser with the highest
                  </span>
                  <span style={{ fontWeight: "700", fontSize: "22px" }}>
                   {highestCount}
                  </span>
                  
                  <span style={{ fontWeight: "normal", fontSize: "14px" }}>
                    {highestPurchaser1}
                  </span>
               
                </div>
              </div>
            </div>
          </Card>
            </div>
        </div>
      </div>

      <div style={{ marginTop: "2rem" }}>
        <table id="userTableinfo">
          <thead>
            <tr>
              <th style={{ width: "40%" }}>Purchaser Name</th>
              <th style={{ width: "auto" }}>Date of Purchase</th>
              <th style={{ width: "auto", textAlign: "center" }}>
                Denomination(in crore)
              </th>
            </tr>
          </thead>
          <tbody>
            {filterDataByDate.length === 0 ? (
              <tr>
                <td colSpan={3} style={{ textAlign: "center" }}>
                  No data available
                </td>
              </tr>
            ) : (
              <>
                {filterDataByDate.map((item, index) => (
                  <tr key={index}>
                    <td>{item["Purchaser Name"]}</td>
                    <td>{item["Date of Purchase"]}</td>
                    <td style={{ textAlign: "center" }}>
                      {item["Denomination"]}
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MyDateRangePickerComponent;
