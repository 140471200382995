import React from "react";
import Chart from "react-apexcharts";

const DonorListBar = (props) => {
  const dataitem = props.data;

  // Sort the array in decreasing order of count
  const to10data = dataitem.sort((a, b) => b.denomination - a.denomination);

  // Get the top 10 denominations
  const top10Denominations = to10data.slice(0, 10);

//   console.log(top10Denominations);

  // Extracting data for the chart
  let categories = top10Denominations.map((item) => item.name);

  const data = top10Denominations.map((item) => item.denomination);
  categories.sort((a, b) => {
    const nameA = convertToShortName(a);
    const nameB = convertToShortName(b);
    return nameA.localeCompare(nameB);
  });
  // Prepare the options for the chart
  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      }
    },
    xaxis: {
      categories: categories,
      labels: {
        formatter: function (value) {
          // Convert large numbers to billions format (e.g., 1000000000 => 1b)
          if (value >= 1000000000) {
            return (value / 1000000000).toFixed(0) + "bilion";
          }
          // Convert large numbers to millions format (e.g., 1000000 => 1m)
          else if (value >= 1000000) {
            return (value / 1000000).toFixed(0) + "milion";
          }
          return value;
        },
        style: {
          colors: "#ffffff",
        },
      },
    
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        if (val >= 1000000000) {
          return (val / 1000000000).toFixed(0) + "b";
        }
        else if (val >= 1000000) {
          return (val / 1000000).toFixed(0) + "m";
        }
        return val;
      },
      offsetX: 0, // Center the data labels within the column
      textAnchor: "middle", // Center the text horizontally
      style: {
        colors: ["#ffffff"],
      },
    },
    fill: {
      colors: ["#93C3EE", "#E5C6A0", "#669DB5", "#94A74A"],
    },

    yaxis: {
      labels: {
        formatter: function (value) {
          // Convert large numbers to billions format (e.g., 1000000000 => 1b)
          if (value >= 1000000000) {
            return (value / 1000000000).toFixed(0) + "b";
          }
          // Convert large numbers to millions format (e.g., 1000000 => 1m)
          else if (value >= 1000000) {
            return (value / 1000000).toFixed(0) + "m";
          }
          return value;
        },
        style: {
          colors: "#ffffff",
        },
      },
    },
    plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 4,
    
        },
    },
    legend: {
      show:false
    },
      
  };

  const series = [
    {
      name: "Denomination",
      data: data,
    },
  ];

  return (
    <div>
      <Chart options={options} series={series} type="bar" height={400} />
    </div>
  );
};
function convertToShortName(name) {
  const firstWord = name.split(' ')[0];
  return firstWord + (name.length > firstWord.length ? '...' : '');
}

export default DonorListBar;
